export const SUBDOMAIN_REGEX = /^[^.]*/;

export function getSubdomain(domain) {
  return SUBDOMAIN_REGEX.exec(domain)[0];
}

export function replaceSubdomain(from, to) {
  return `https://${from.replace(SUBDOMAIN_REGEX, to)}/`;
}

export function getBaseUrl(origin) {
  if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_BASE_URL || 'https://api.dev.sunfish.at/';
  }

  return replaceSubdomain(origin, 'api');
}
