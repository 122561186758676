/**
 * Checks if the value is a valid http url, and optionally
 * if it ends in a certain file type (one of the options provided)
 *
 * @param {String} value value to be tested
 * @param {Array} types (optional) array of file types to be checked
 *
 * @return {Boolean} if the value is a valid url (of type)
 */
export function isHttpUrl(value, types) {
  let url;

  try {
    url = new URL(value);
  } catch {
    // invalid url
    return false;
  }

  if (!['http:', 'https:'].includes(url.protocol)) {
    // invalid protocol
    return false;
  }

  if (types) {
    const ext = url.pathname.split('.').pop();
    return types.includes(ext);
  }

  return true;
}
