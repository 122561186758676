import PropTypes from 'prop-types';
import classNames from 'classnames';

const SIZE = {
  xxs: 'h-2 w-2 border',
  xs: 'h-4 w-4 border',
  sm: 'h-8 w-8 border-2',
  md: 'h-16 w-16 border-4',
  lg: 'h-24 w-24 border-4',
  xl: 'h-64 w-64 border-8',
};

const COLORS = {
  'primary': 'border-gray-300 border-t-indigo-700',
  'white': 'border-gray-300 border-t-white',
};

function Spinner({ size, color, className }) {
  return (
    <div className={classNames(
      'block animate-spin rounded-full',
      SIZE[size],
      COLORS[color],
      className,
    )}>
      <span className="sr-only">Loading</span>
    </div>
  );
}

Spinner.propTypes = {
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
  color: PropTypes.oneOf(['primary', 'white']),
};

Spinner.defaultProps = {
  size: 'md',
  color: 'primary',
};

export default Spinner;
