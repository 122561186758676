import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSearchParams } from 'react-router-dom';

import useCurrentOrganisation from 'hooks/use-current-organisation';
import useNotification from 'hooks/use-notification';
import useAuth from 'hooks/use-auth';
import { getSubdomain } from 'utils/domain';
import Spinner from 'components/spinner';

import Announcements from 'features/system-notifications/announcements';

import { ReactComponent as SunfishLogo } from 'assets/sunfish.svg';

import AppRoutes from './app-routes';
import ErrorFallback from './error-fallback';

function AppBase() {
  const auth = useAuth();
  const currentOrganisation = useCurrentOrganisation();
  const [searchParams] = useSearchParams();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (searchParams.get('e')) {
      showNotification({
        type: 'error',
        title: 'Error',
        description: 'An unexpected error occured. We brought you back to a safe place.',
      });
    }
  }, [showNotification, searchParams]);

  const subdomain = getSubdomain(window.location.host);
  if (!currentOrganisation.isSuccess && subdomain !== 'superadmin') {
    return (
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mx-auto">
          {currentOrganisation.isLoading && (
            <Spinner size="md" />
          )}
          {currentOrganisation.isError && (
            <>
              <div className="w-48 sm:w-96">
                <SunfishLogo className="text-sf-dark" />
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:ml-[28px] sm:mr-[50px] sm:mt-[-20px]">
                <div className="text-sm lowercase font-normal text-gray-700 ">
                  {subdomain && !['www', 'dev', 'staging'].includes(subdomain) && (
                    <>
                      <span className="text-red-600 font-medium">Error:</span> URL is not valid
                    </>
                  )}
                </div>
                <div className="text-sm text-gray-700 font-normal text-right sm:text-left">
                  by <a className="text-indigo-600 font-medium hover:text-indigo-500" href="https://www.seso.at" target="_blank" rel="noopener noreferrer">seso</a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.replace(`${window.location.origin}?e=unknown`);
      }}
    >
      <AppRoutes />
      {subdomain !== 'superadmin' && auth.isLoggedIn && <Announcements />}
    </ErrorBoundary>
  );
}

export default AppBase;
