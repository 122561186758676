import { BrowserRouter } from 'react-router-dom';

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { Toaster } from 'react-hot-toast';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ProvideAuth from 'features/auth/provide-auth';

import AppBase from './app-base';
import './day';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    },
  },
});
window.queryClient = queryClient; // store for out of component access

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ProvideAuth>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <Toaster
              position="top-right"
              reverseOrder={true}
              toastOptions={{
                duration: 20000,
              }}
            />
            <AppBase />
          </DndProvider>
        </BrowserRouter>
      </ProvideAuth>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
